import { useSettings } from '@guider-global/front-end-hooks';
import { useIsSafariOrIOS } from '@guider-global/front-end-utils';
import { useBaseLanguage } from '@guider-global/sanity-hooks';
import { IRelationship } from '@guider-global/shared-types';
import { Button, ButtonStack, SurveyModal } from '@guider-global/ui';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { SurveyAvatar } from 'components';
import { useLocalization } from 'hooks';
import { ConfirmationModal } from 'modals';
import { RelationshipSessionsModal } from 'modals/Relationship';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface IAutoConcludeModalProps {
  isReengaged: boolean;
  relationship: IRelationship;
  organizationSlug: string;
  isLoading: boolean;
  isActiveGroup?: boolean;
  getModalConfig: () => any;
  handleConcludeRelationship: () => void;
  isGuide: boolean;
  handleScheduleSession: () => void;
  handleSessionsModalClose: () => void;
  setIsConcludeDialog: (value: boolean) => void;
  isConcludeDialog: boolean;
  isSessionsModalOpen: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

export const AutoConcludeModal: React.FC<IAutoConcludeModalProps> = ({
  isReengaged,
  relationship,
  organizationSlug,
  isLoading,
  isGuide,
  getModalConfig,
  handleConcludeRelationship,
  handleScheduleSession,
  handleSessionsModalClose,
  setIsConcludeDialog,
  isConcludeDialog,
  setIsLoading,
}) => {
  const [isSessionsModalOpen, setIsSessionsModalOpen] = useState(false);
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const modalConfig = getModalConfig();
  const IsSafariOrIOS = useIsSafariOrIOS();
  const { settings } = useSettings({
    options: { keepPreviousData: true },
  });
  const timeZone = settings?.at(0)?.timezone ?? 'Europe/London';
  const navigate = useNavigate();

  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('md'));

  const modalDescription =
    baseLanguage?.globals?.relationships?.auto_conclude_relationship_modal
      ?.modal_description;

  const getProfileInfo = (isGuide: boolean, relationship: IRelationship) => {
    const profileKey = isGuide ? 'traineeProfiles' : 'guideProfiles';
    const profile = relationship?.[profileKey]?.[0] || {};
    return {
      roleSingular: isGuide ? 'Trainee' : 'Guide',
      firstName: profile.firstName,
      lastName: profile.lastName,
    };
  };

  const formatDescription = (
    description: string,
    profileInfo: {
      roleSingular: string;
      firstName?: string;
      lastName?: string;
    },
  ) => {
    return description
      .replace('{{ roleSingular }}', profileInfo.roleSingular)
      .replace('{{ firstName }}', profileInfo.firstName ?? '')
      .replace('{{ lastName }}', profileInfo.lastName ?? '');
  };

  const profileInfo = getProfileInfo(isGuide, relationship);
  const formattedDescription = formatDescription(
    modalDescription ?? '',
    profileInfo,
  );

  const closeSessionModal = () => {
    handleSessionsModalClose();
    setIsSessionsModalOpen(false);
  };

  const handleLogSession = () => {
    navigate(`/relationships/${relationship.id}/sessions/log`);
    setIsSessionsModalOpen(true);
  };

  return (
    <>
      <SurveyModal
        open={isReengaged}
        title={
          baseLanguage?.globals?.relationships?.auto_conclude_relationship_modal
            ?.modal_title ?? ''
        }
        heading={`${profileInfo.firstName ?? ''} ${profileInfo.lastName ?? ''}`}
        subheading={profileInfo.roleSingular}
        textSize="xs"
        minWidth="700px"
        Avatar={
          !IsSafariOrIOS ? (
            <SurveyAvatar
              relationshipId={relationship.id}
              isGuide={isGuide ?? false}
            />
          ) : (
            <></>
          )
        }
      >
        <Box>
          <Typography variant="body1" sx={{ textAlign: 'justify', mb: 4 }}>
            {formattedDescription}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column-reverse' : 'row',
            alignItems: isMobile ? 'normal' : 'center',
            justifyContent: 'space-between',
            pb: 2,
            gap: isMobile ? 3 : 0,
          }}
        >
          <Button
            variant="contained"
            color="error"
            onClick={() => setIsConcludeDialog(true)}
            data-cy="conclude-relationship-button"
            label={
              baseLanguage?.globals?.relationships
                ?.conclude_relationship_button_label
            }
          />

          <ButtonStack
            spacing={1}
            direction={isMobile ? 'column' : 'row'}
            buttonsWidth={isMobile ? '100%' : 'auto'}
            justifyContent={isMobile ? 'space-between' : 'flex-end'}
            buttons={[
              {
                key: 'new-session',
                variant: 'contained',
                label: baseLanguage?.globals?.sessions?.schedule_session,
                color: 'info',
                onClick: () => handleScheduleSession(),
                'data-cy': 'relationship-schedule-session-button',
              },
              {
                key: 'log-session',
                variant: 'outlined',
                label:
                  baseLanguage?.globals?.sessions?.log_session_button_label,
                color: 'info',
                onClick: () => handleLogSession(),
                'data-cy': 'relationship-log-session-button',
              },
            ]}
          />
        </Box>
      </SurveyModal>
      {isSessionsModalOpen && (
        <RelationshipSessionsModal
          relationship={relationship}
          handleClose={closeSessionModal}
          isGuide={isGuide}
          timezone={timeZone}
          isActiveGroup={false}
          isGroupProgram={false}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      {isConcludeDialog && (
        <ConfirmationModal
          title={modalConfig.title}
          description={modalConfig.description}
          open={isConcludeDialog}
          isLoading={isLoading}
          actions={[
            {
              label: modalConfig.cancelButtonLabel,
              action: () => setIsConcludeDialog(false),
              color: 'inherit',
              variant: 'outlined',
              textColor: 'text.secondary',
              isLoadingButton: true,
            },
            {
              label: modalConfig.buttonLabel,
              action: handleConcludeRelationship,
              color: 'info',
              variant: 'contained',
              isLoadingButton: true,
              dataCyLabel:
                'components_relationship_relationshipConclude_relationshipsConcludeDialog_button',
            },
          ]}
          handleClose={() => setIsConcludeDialog(false)}
        />
      )}
    </>
  );
};
